import { Box, Container, Heading, Stack, VStack } from '@chakra-ui/react';
import { GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import normalize from 'normalize-url';
import { useCallback, useState } from 'react';

import ForgotPasswordForm from 'components/forms/forgot-password';
import LoginForm from 'components/forms/login';
import RegisterForm from 'components/forms/register';
import AuthLayout from 'components/layout/auth-layout';
import { getCurrencyExchangeRates } from 'prisma/api/currency';
import { PageComponent } from 'types';

const Login: PageComponent = () => {
  const { t } = useTranslation('account');
  const [route, setRoute] = useState('login');
  const router = useRouter();

  const onLoginSubmit = useCallback(() => {
    router.push((router.query.redirectTo as string) || '/');
  }, [router]);

  let redirectUrl: string;

  if (router.query.redirectTo) {
    redirectUrl = normalize(
      `${process.env.NEXT_PUBLIC_CONFIRM_ACCOUNT_REDIRECT_URL}/${
        router.query.redirectTo as string
      }`,
    );
  }

  let visibleForm: React.ReactNode;
  let accountTabTitle: string;

  switch (route) {
    case 'reset-password':
      visibleForm = <ForgotPasswordForm onRouteChange={setRoute} fullWidthButton={false} />;
      accountTabTitle = t('account.resetPassword');
      break;
    case 'login':
    default:
      visibleForm = (
        <LoginForm onRouteChange={setRoute} fullWidthButton={false} onSubmit={onLoginSubmit} />
      );
      accountTabTitle = t('common:enter');
  }

  return (
    <Container py={20} maxW="container.xl" centerContent>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        justify="space-between"
        spacing={{ base: 24, lg: 32, xl: 48 }}
        w="full"
      >
        <VStack spacing={10} w="full">
          <Heading as="h2" size="md" textTransform="uppercase" textAlign="center" w="full">
            {accountTabTitle}
          </Heading>
          <Box w="full">{visibleForm}</Box>
        </VStack>

        <VStack spacing={10} w="full">
          <Heading as="h2" size="md" textTransform="uppercase" textAlign="center" w="full">
            {t('common:register.register')}
          </Heading>
          <Box w="full" h="full">
            <RegisterForm fullWidthButton={false} redirectUrl={redirectUrl} />
          </Box>
        </VStack>
      </Stack>
    </Container>
  );
};

Login.getLayout = (page) => (
  <AuthLayout title="pages.login" redirectOnAuthenticated>
    {page}
  </AuthLayout>
);

export const getStaticProps: GetStaticProps = async () => {
  const conversionRates = await getCurrencyExchangeRates();

  return {
    props: {
      conversionRates,
    },
    revalidate: 2600 * 24, // 1 day
  };
};

export default Login;
